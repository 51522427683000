import { getZXingModule, readBarcodesFromImageData } from 'zxing-wasm';
export default {
  name: 'LBAQRCodeScanner',
  props: {
    fullscreen: {
      type: Boolean,
      default: false
    },
    videoWidth: {
      type: Number,
      default: 400
    },
    videoHeight: {
      type: Number,
      default: 300
    },
    tryHarder: {
      type: Boolean,
      default: false
    },
    facingMode: {
      type: String,
      default: 'environment' // 'environment' or 'user'
    },
    showScanTime: {
      type: Boolean,
      default: true
    },
    showErrorMessage: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      result: null,
      scanTime: null,
      errorMessage: null,
      zxingModule: null,
      startTime: null,
      isScanning: false
    };
  },
  async mounted() {
    try {
      this.zxingModule = await getZXingModule();
      this.startScanning();
    } catch (error) {
      this.errorMessage = 'Fehler beim Laden des ZXing-Moduls: ' + error.message;
    }
  },
  methods: {
    async startScanning() {
      if (this.isScanning) return;
      this.isScanning = true;
      try {
        const videoElement = this.$refs.video;
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: this.facingMode
          }
        });
        videoElement.srcObject = stream;
        videoElement.play();

        // Request fullscreen mode if enabled
        if (this.fullscreen) {
          if (videoElement.requestFullscreen) {
            videoElement.requestFullscreen();
          } else if (videoElement.mozRequestFullScreen) {
            /* Firefox */
            videoElement.mozRequestFullScreen();
          } else if (videoElement.webkitRequestFullscreen) {
            /* Chrome, Safari and Opera */
            videoElement.webkitRequestFullscreen();
          } else if (videoElement.msRequestFullscreen) {
            /* IE/Edge */
            videoElement.msRequestFullscreen();
          }
        }

        // Wait for the video to be ready
        videoElement.onloadedmetadata = () => {
          this.startTime = new Date().getTime();
          this.scan();
        };
      } catch (error) {
        this.handleError(error);
      }
    },
    async scan() {
      if (!this.isScanning || !this.zxingModule) return;
      const videoElement = this.$refs.video;
      if (videoElement.readyState !== videoElement.HAVE_ENOUGH_DATA) {
        requestAnimationFrame(this.scan);
        return;
      }
      const canvas = document.createElement('canvas');
      canvas.width = this.videoWidth;
      canvas.height = this.videoHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      try {
        const results = await readBarcodesFromImageData(imageData, {
          tryHarder: this.tryHarder
        });
        if (results.length > 0) {
          this.result = results[0].text;
          const endTime = new Date().getTime();
          this.scanTime = endTime - this.startTime;

          // Emit the result as a custom event
          this.$emit('scanned', this.result);

          // Exit fullscreen mode if enabled
          if (this.fullscreen) {
            if (document.exitFullscreen) {
              document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
              /* Firefox */
              document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
              /* Chrome, Safari and Opera */
              document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
              /* IE/Edge */
              document.msExitFullscreen();
            }
          }
          const stream = videoElement.srcObject;
          stream.getTracks().forEach(track => track.stop());
          this.isScanning = false;

          // Warte 1 Sekunde und starte den nächsten Scanvorgang
          setTimeout(() => {
            this.startScanning();
          }, 1000);
          return;
        }
      } catch (error) {
        console.error('Scan-Fehler:', error);
      }
      requestAnimationFrame(this.scan);
    },
    handleError(error) {
      console.error(error);
      if (error.name === 'NotAllowedError') {
        this.errorMessage = 'Zugriff auf die Kamera verweigert. Bitte Kamera-Berechtigungen erteilen.';
      } else if (error.name === 'NotFoundError') {
        this.errorMessage = 'Keine Kamera gefunden. Bitte eine Kamera anschließen.';
      } else {
        this.errorMessage = `Ein Fehler ist aufgetreten: ${error.message}`;
      }
      this.isScanning = false;
    }
  }
};