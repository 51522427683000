var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "qr-barcode-reader"
  }, [_c('video', {
    ref: "video",
    attrs: {
      "width": "400",
      "height": "300",
      "autoplay": "",
      "playsinline": ""
    }
  }), _vm.showScanTime && _vm.scanTime ? _c('p', [_vm._v(" Zeit für das Scannen: " + _vm._s(_vm.scanTime) + " Millisekunden ")]) : _vm._e(), _vm.showErrorMessage && _vm.errorMessage ? _c('p', {
    staticClass: "error-message"
  }, [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };